import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteUnitDetail } from "../../../redux/api";
import DeleteModal from "../../utils/DeleteModal";

const FestTableRow = ({
    index,
    detail,
    date,
    name,
    phone, email,
    id,
    property,
    handleDelete
}) => {

    const creaDate = new Date(date).toISOString().slice(0, 10)
    const [year, month, day] = creaDate.split('-');

    let newDate = `${day}-${month}-${year}`;
    return (
        <>
            <tr>
                <td>{index + 1}</td>
                <td>{newDate}</td>
                <td>{name}</td>
                <td>{phone}</td>
                <td>{email}</td>
                {/* <td>{property}</td> */}
            </tr>
        </>
    )
}

export default FestTableRow