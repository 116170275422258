import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { deletePassDetails, deleteUnitDetail } from "../../../redux/api";
import DeleteModal from "../../utils/DeleteModal";

const FestTableRow = ({
    index,
    date,
    code,
    firstName,
    lastName,
    phone, email,
    id,
    projectName,
    USPData,
    setUSPData
}) => {

    const creaDate = new Date(date).toISOString().slice(0, 10)
    const [year, month, day] = creaDate.split('-');
    let newDate = `${day}-${month}-${year}`;

    const [deleteModalOpen, setdeleteModalOpen] = useState(false);
    const [ConfirmDelete, setConfirmDelete] = useState(false);

    const handleDeleteConfirm = () => {
        setdeleteModalOpen(false);
        setConfirmDelete(true);
    };
    const handleDeleteCancel = () => {
        setdeleteModalOpen(false);
    };

    const handleDeleteProperty = (e) => {
        e.preventDefault();
        setdeleteModalOpen(true);
    }

    const handleConfirmDeleteProperty = async (id) => {
        try {
            const updatedData = USPData.filter((b) => b._id !== id);
            setUSPData(updatedData);
            await deletePassDetails(id);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (ConfirmDelete) {
            handleConfirmDeleteProperty(id);
        }
    }, [ConfirmDelete]);

    return (
        <>
            <tr>
                <td>{code}</td>
                <td>{newDate}</td>
                <td>{firstName + " " + lastName || ""}</td>
                <td>{email}</td>
                <td>{phone}</td>
                <td>{projectName}</td>
                <td style={{ textAlign: "center" }}>
                    <Link onClick={(e) => handleDeleteProperty(e)} to={"#"}>
                        <button className="delete-btn">
                            <DeleteIcon />{" "}
                        </button>
                    </Link>
                </td>
                {/* <td>{property}</td> */}
            </tr>
            {deleteModalOpen && (
                <DeleteModal
                    show={deleteModalOpen}
                    handleConfirm={handleDeleteConfirm}
                    handleCancel={handleDeleteCancel}
                    categorytag="Inquiry"
                />
            )}
        </>
    )
}

export default FestTableRow