import moment from "moment";
import React from "react";
const CtableRow = ({ index, contact }) => {
    return (
        <>
            <tr>
                <td>{index + 1}</td>
                <td>{contact.name}</td>
                <td>{contact.phone}</td>
                <td>{moment(contact.createdAt).format("MM/DD/YYYY")}</td>
            </tr>
        </>
    );
};

export default CtableRow;
